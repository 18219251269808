import React, { useEffect, useState, useLayoutEffect } from 'react'
import styled, { css } from "styled-components"
import { ColorPalette as CP, MainContents } from "./BaseStyles.js"
import { Link, useLocation } from 'react-router-dom'
import SEO from "./SEO.js"
import Slider from "./Slider.js"

import logo from './logo_vertical.png'
import logoJp from './logo_jp_vertical.png'
import Posts from "./Posts.js"
import PreLoaderTop from "./PreLoader.js"

import iconEmail from './icon-email.svg'
import iconInstagram from './icon-instagram.svg'
import iconTwitter from './icon-twitter.svg'



const Top = () => {

  const [anime, setAnime] = useState(false);

  const [aboutTextData, setaboutTextData] = useState('');
  const [independenceImageSrc, setIndependenceImageSrc] = useState('');
  const [descriptionTextData, setDescriptionTextData] = useState('');
  const preloaderAnimationSpeed = 7770;



  useLayoutEffect(() => {
    fetch('https://imediea.net/wp/wp-json/wp/v2/pages/127')
      .then((response) => response.json())
      .then((pageData) => {
        setaboutTextData(pageData.content.rendered.replace(/<img(?:.*?)src=[\"\'](.*?)[\"\'](?:.*?)>/gi, ''))
      })
    fetch('https://imediea.net/wp/wp-json/')
      .then((response) => response.json())
      .then((pageData) => {
        setDescriptionTextData(pageData.description)
      })
  }, [])

  useLayoutEffect(() => {
    topImageLoader(2)
      .then((img) => {
        setIndependenceImageSrc(img[img.length - 1])
      })
    // window.addEventListener('load', () => {
    //   setAnime(true)
    // })
  }, [])

  const { pathname } = useLocation();
  useEffect(() => {
    setAnime(true)
  }, [pathname])

  return (
    <>
      <Home>
        <SEO
          title={"TOP"}
          description={descriptionTextData}
          pathSlug={``}
          ogImage={''}
        />
        {anime && <PreLoaderTop anime={e => setAnime(e)} animeState={anime} />}
        <LogoWrap className={"left"}>
          <img src={logo} />
          <Separator className={"left"}>
          </Separator>
        </LogoWrap>
        <HomeMain>
          <SliderWrap>
            <Slider displayTime={'8000'} switchingTime={'1200'} delay={preloaderAnimationSpeed} />
          </SliderWrap>
          <IndependenceImage>
            <Link to={'./issues'}>
              {<img {...independenceImageSrc} />}
            </Link>
          </IndependenceImage>
          <MoromoroWrap>
            <SocialLinks>
              <ul>
                <li>
                  <a href={'https://twitter.com/imedieamag/'} target={"_blank"} rel={"noopener noreferrer"}>
                    <img src={iconTwitter} />
                  </a>
                </li>
                <li>
                  <a href={'https://instagram.com/imediea_mag/'} target={"_blank"} rel={"noopener noreferrer"}>
                    <img src={iconInstagram} />
                  </a>
                </li>
                <li>
                  <a href={'mailto:imediea.mag@gmail.com'} >
                    <img src={iconEmail} />
                  </a>
                </li>
              </ul>
            </SocialLinks>
            <AboutLinks>
              <Link to={'/about'}>
                <section dangerouslySetInnerHTML={{ __html: aboutTextData }} />
              </Link>
            </AboutLinks>
          </MoromoroWrap>
        </HomeMain>
        <LogoWrap className={"right"}>
          <img src={logoJp} className={"right_logo"} />
          <Separator className={"right"}>
          </Separator>
        </LogoWrap>
      </Home>
      <ArticleSeparator></ArticleSeparator>
      <MainContents
        customStyles={
          css`
            padding: 18vw 0 0 4.7vw;
            min-height: 120vh;
            width: calc(89.8vw - 22.3% );
            @media (max-width: 768px){
              width: calc(89vw - 4% );
              padding: 18vw 0 0 5.5vw;
              /* padding: 18vw 0 0 0; */
            }
            ul{
              /* width:calc(83vw * 0.9); */
              box-sizing: border-box;
              width:100%;
            }
          `
        }
      >
        <section>
          <Posts titleText={'Column'} />
        </section>
      </MainContents>
    </>
  );
}


const Home = styled.div`
  display:flex;
  justify-content:space-between;
`

const HomeMain = styled.div`
  border: 1pt solid ${CP.red};
  border-top: none;
  border-bottom: none;
  flex:1;
  @media (max-width:768px){
    border-width: 0.7pt;
  }
`

let LogoWrap = styled.div`
  width: 22.3%;
  padding:18vw 0 0 0;
  box-sizing: border-box;
  border-bottom: 1pt solid ${CP.red};
  position: relative;
  @media (max-width:768px){
    border-width: 0.7pt;
  }
  &.right{
    border:none;
  }
  img{
    width:47.9%;
    margin: 0 auto 0 28.9%;
    left: 0;
    right: 0;
    display:block;
  }
  

  @media (max-width:768px){
    &.right{
      width: 16%;
    }
    img.right_logo{
      display:none;
    }
  }
`


const SliderWrap = styled.div`
      width: 118.9%;
      height:35.4vw;
      /* overflow: hidden; */
      position: relative;
      margin: 10.4vw 0 0 0;
      background: ${CP.white};
      left: -8%;
      transform: translate(calc(18.9% - 6px), 0);
      z-index:2;
      @media (max-width:768px){
        width: 105.55%;
        height: 36.4vw;
      }
      img{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      `

const IndependenceImage = styled.div`
  width: 69%;
  height: 26.5vw;
  overflow: hidden;
  position: relative;
  margin: 33.3vw auto 0 auto;
  left: 0;
  right: 0;
  img{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

const MoromoroWrap = styled.div`
  width:100%;
  border-bottom: 1pt solid ${CP.red};
  @media (max-width:768px){
    border-width: 0.7pt;
  }
  height:28.6vw;
`

const SocialLinks = styled.div`
  width: 5.766vw;
  height: 33vw;
  /* width*9.10465116 */
  border: 1px solid ${CP.red};
  position: relative;
  /* top: calc(700px + 20vw); */
  margin: 12.5vw 0 0 0;
  left: -3vw;
  background: ${CP.white};
  padding: 3vw 0;
  box-sizing: border-box;
  ul{
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content:space-around;
    align-items:center;
    li{
      width: 3vw;
    }
  }
`
const AboutLinks = styled.div`
  width: 17.9vw;
  height: 25.2vw;
  border: 1px solid ${CP.red};
  background: ${CP.white};
  position: relative;
  top:-12.833vw;
  left: 43.5vw;
  padding: 1vw;
  box-sizing: border-box;
  display: flex;
  align-content: auto;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  p{
    color: ${CP.red};
    text-align: left;
    font-size: 1.2vw;
    line-height: 1.4;
    font-family: "Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
    +p{
      margin: 1.2vw 0 0 0;
    }
  }
  @media (max-width:768px){
    left: 50vw;
    p{
      color: ${CP.red};
      overflow:hidden;
      font-size: 11px;
      font-family: "Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
      +p{
        margin: 11px 0 0 0;
      }
    }
  }

`

const Separator = styled.div`
  width:100%;
  box-sizing: border-box;
  margin: 0 0 0 0;
  left: 0;
  background: ${CP.white};
  position: absolute;
  z-index:-1;
  &.right{
    top: 155.5vw;
    /* position: sticky; */
    /* top: 0; */
    border-left:1pt solid ${CP.red};
    border-top: 1pt solid ${CP.red};
    @media (max-width:768px){
      border-width: 0.7pt;
    }
    background: #0000;
    @media(max-width:768px){
      height: 20vw;
    }
  }
  &.left{
    top: 151.1vw;
    height: 7.4vw;
    border-right:1pt solid ${CP.red};
    @media (max-width:768px){
      border-width: 0.7pt;
    }
  }
`

const ArticleSeparator = styled.div`
  top: 0;
  height: 100vh;
  right:22.3% ;
  border-left:1pt solid ${CP.red};
  position: fixed;
  z-index:-100;
  @media(max-width:768px){
    display: none;
  }
`





export async function topImageLoader(folderId) {
  const testPageData = { success: true, data: { attachment_ids: ["208", "502", "503"] } }

  const getImageID = (folderId) => {
    return new Promise(resolve => {
      fetch('https://imediea.net/wp/wp-json/filebird/public/v1/attachment-id/?folder_id=' + folderId)
        .then((response) => response.json())
        .then((pageData) => {
          console.log('success');
          resolve([...pageData.data.attachment_ids])
        })
        .catch(_ => {
          console.log('fail');
          resolve([...testPageData.data.attachment_ids])
        });
    })
  }

  const getImageData = (imageIDs) => {
    return new Promise(resolve => {
      const imageData = fetch('https://imediea.net/wp/wp-json/wp/v2/media/?order=asc&orderby=title&include=' + imageIDs.join())
        .then((response) => response.json())
        .then((img) => {
          return img
        })
      resolve(imageData);
    })
  }

  async function imageDataLoader(folderId) {
    const imagedata = await getImageData(await getImageID(folderId))
    return imagedata;
  }


  return await imageDataLoader(folderId)
    .then((ugyas) => {
      return ugyas.map((ugya) => {
        let secretsOut = '';
        Object.values(ugya.media_details.sizes).forEach((secret) => {
          secretsOut += `${secret.source_url} ${secret.width}w,`
        })
        return {
          src: ugya.source_url,
          loading: "lazy",
          // srcSet: secretsOut,
          // sizes: "(max-width: 300px) 100vw,300px",
        }
      })
    })
}

export default Top;