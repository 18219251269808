import { css } from "styled-components"
import React, { useEffect, useState } from 'react'
import SEO from './SEO.js'
import { Outline, MainContents, Article } from "./BaseStyles.js"

const About = () => {

  const [pageData, setPageData] = useState([])
  useEffect(() => {
    const url = 'https://imediea.net/wp/wp-json/wp/v2/pages/8'
    fetch(url)
      .then((response) => response.json())
      .then((data) => {

        setPageData(
          {
            pageData: data,
            loaded: true,
          }
        )
      })
  }, [])

  const loadingMessage = `<p>now loading</p>`
  const remderedArticleData = pageData.loaded && pageData.pageData.content.rendered.replace(/<p>(<img .* \/>).{0,}<\/p>/gi, "$1").replace(/<p>.*<\/p>/gi, "")

  return (
    <MainContents
      customStyles={
        css`
                border-left:none;
                margin: 0 0 0 0;
                width:100%;
            `
      }
    >
      <SEO title={"About - "} />
      <h1>
        <Outline fontSize={
          {
            max: '55px',
            midium: '42px',
            mini: '28px'
          }
        }>
          {pageData.loaded && "About"}
        </Outline>
      </h1>
      {pageData.loaded && <Article
        dangerouslySetInnerHTML={{ __html: pageData.pageData.content.rendered || loadingMessage }}
        outlinedLetter={{
          max: '55px',
          midium: '42px',
          mini: '28px'
        }}
        customStyles={
          css`
              p.en{
                text-align: left;
              }
            `
        }
        isPage={true}
      />}
    </MainContents>
  )
}

export default About;