import React, { useEffect, useState, useCallback } from 'react'
import styled, { css } from "styled-components"
import { Link } from 'react-router-dom'

import menuButtonActive from "./menu_button_active.svg"
import menuButtonPassive from "./menu_button_passive.svg"

import logoInMenuSrc from "./logo_in_menu_vertical.png"
import { ColorPalette as CP, MenuActiveStyles } from "./BaseStyles.js"


const menuButtons = [menuButtonPassive, menuButtonActive]


const Menu = (props) => {
  const [menuButtonIsActive, setMenuButtonIsActive] = useState(false);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    MenuActiveStyles(menuButtonIsActive)
    document.body.style.overflow = menuButtonIsActive ? "hidden" : "scroll";
  }, [menuButtonIsActive])

  return (
    <div>
      <MenuButton
        src={
          menuButtons[Number(menuButtonIsActive)]
        }
        onClick={() => {
          setMenuButtonIsActive(!menuButtonIsActive)
          setAnimate(false)
          setTimeout(() => {
            setAnimate(!menuButtonIsActive && true)
          }, 10)
        }}
        isActive={menuButtonIsActive}
      />
      <ManuMain
        className={animate && 'active'}
        onClick={
          () => {
            setMenuButtonIsActive(false)
            setAnimate(!menuButtonIsActive && false)
          }
        }
      >
        <LogoInMenu>
          <img src={logoInMenuSrc} />
        </LogoInMenu>
        <div className={'menu_wrap'}>
          <MenuTitle>
            <Link to={'/'}>
              i + med(i/e)a mag
            </Link>
          </MenuTitle>
          <MenuWrap itemLength={props.menulist.length}>
            {
              props.menulist.map((menu, i) => {
                return (
                  menu.inMenu &&
                  <li
                    key={i}
                    className={`item${i} ${animate ? 'active' : ''}`}
                    onClick={
                      () => {
                        setMenuButtonIsActive(false)
                        setAnimate(!menuButtonIsActive && false)
                      }
                    }
                  >
                    {menu.external
                      ? <a href={menu.link} target={"_blank"} rel={'noopener noreferrer'}>-<span>{menu.pageTitle}</span></a>
                      : <Link to={menu.link}>-<span>{menu.pageTitle}</span></Link>
                    }
                  </li>
                )
              })
            }
          </MenuWrap>
        </div>
      </ManuMain>
    </div>
  )

}

const MenuButton = styled.img`
      height: 20px;
      position: absolute;
      right: 16px;
      top: 20px;
      cursor: pointer;
      z-index: 1;
      @media (max-width: 768px){
        right: 12px;
        top: 16px;
      }
`

const ManuMain = styled.div`
      width: 100vw;
      min-height: 100vh;
      height: 100vh;
      height: -webkit-fill-Available;
      background: ${CP.red};
      position:fixed;
      top: 0vh;
      right: 0;
      opacity:0;
      visibility: hidden;
      transition: all .8s;
      display: flex;
      > div.menu_wrap{
        padding: 20vh 0 0 10%;
      }
      &.active{
        opacity:1;
        visibility: inherit;
      }
      `


const LogoInMenu = styled.div`
      height: 100vh;
      width:22.3%;
      /* width:14.14vw; */
      border-right: 1px solid ${CP.white};
      padding: 0 0px 0 0;
      display: flex;
      img{
        margin: 20vh auto auto auto;
        height: 40%;
        top: 0px;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
      }
      `

const MenuWrap = styled.ul`
      margin: 48px 0 0 0;
      li{
        transition: all .5s;
        font-size:36px;
        line-height:1.75;
        margin: 0 0 0 -20px;
        opacity:0;
        color: ${CP.white};
        @media (max-width: 768px){
          font-size: 24px;
        }
        a{
          color: ${CP.white};
          -webkit-text-stroke: 0.466px ${CP.red};
        }
        span{
          margin: 0 0 0 36px;
          font-family: "Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
          }
      }
      li.active{
        margin: 0 0 0 0px;
        opacity:1;
      }
      ${props => createListAnimation(props.itemLength)}
      `

const MenuTitle = styled.h1`
      font-size:36px;
      a{
        font-family: "Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
        color: ${CP.white};
      }
      @media (max-width: 768px){
        font-size: 24px;
      }

      `

const createListAnimation = (listLength) => {
  let styles = '';

  for (let i = 0; i < listLength; i += 1) {
    styles += `
       li.item${i}.active {
        transition-delay: ${i * 0.1}s;
       }
     `
  }
  return css`${styles}`;
}

export default Menu;