import React, { useEffect, useState } from 'react';
import {
  ColorPalette as CP,
  Article,
  Outline,
  MainContents
} from "./BaseStyles.js"
import { LogoText } from "./Pager.js"
import SEO from "./SEO.js"

import styled, { css } from "styled-components"



const Contact = () => {
  const [postData, setPostData] = useState([])
  const [isButtonActive, buttonActivation] = useState(false)
  const [isSending, setSending] = useState(false)
  const [input, setInput] = useState({
    post: Number(85),
    author_email: '',
    author_name: '',
    content: '',
  })
  const [msg, setMsg] = useState('')
  useEffect(() => {
    fetch(`https://imediea.net/wp/wp-json/wp/v2/pages/85`)
      .then((response) => response.json())
      .then((postData) => {
        setPostData({
          loaded: true,
          data: postData
        })
      })
    // fetch(`https://imediea.net/wp/wp-json/contact-form-7/v1/contact-forms/210`)
    //   .then((response) => response.json())
    //   .then((postData) => {
    //     console.log(postData);
    //   })
  }, [])

  useEffect(() => {
    buttonActivation(
      input.author_email !== '' && input.author_name !== '' && input.content !== '' ? true : false
    )
  }, [input])


  const send = (e) => {
    let ma = `author_name=${encodeURI(e.author_name)}&author_email=${encodeURI(e.author_email)}&post=85&content=${encodeURI(e.content)}`
    // fetch('https://imediea.net/wp/wp-json/wp/v2/comments', {
    fetch('https://imediea.net/wp/wp-json/wp/v2/comments', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: ma
    })
      .then(response => {
        console.log(response.status);
        response.json()
      })
      .catch(err => {
        console.log(err);
        setMsg(Object.values(err.data.details).message)
      })
      .then(res => {
        console.log(res.status);
        setTimeout(() => {
          setSending(false);

          setMsg(res.message)
        }, 3000, _ => setMsg(''))
      })


  }

  const loadingMessage = `<p>now loading</p>`

  return (
    <MainContents>
      <SEO
        title={`Contact`}
        description={null}
        pathSlug={`contact`}
        ogImage={null}
      />
      <h1>
        <LogoText fontSize={
          {
            max: '55px',
            midium: '42px',
            mini: '28px'
          }
        } /><Outline fontSize={
          {
            max: '55px',
            midium: '42px',
            mini: '28px'
          }
        }> - Contact</Outline>
      </h1>
      <Article
        dangerouslySetInnerHTML={{ __html: postData.loaded ? postData.data.content.rendered : loadingMessage }}
        outlinedLetter={{
          max: '55px',
          midium: '42px',
          mini: '28px'
        }}
        customStyles={
          css`
            padding: 0 0 0 0;
            margin: 82px 0 0 0;
            @media (max-width: 768px){
              padding: 0 0 0 5.5vw;
            }
            p{
              padding: 0 !important;
            }
          `
        }
        isPage={true}
      >
      </Article>
      {postData.loaded &&
        <ContactForm>
          <ul>
            <li>
              <span>お名前 / Name</span>
              <input
                value={input.author_name}
                onChange={
                  (e) => {
                    setInput({
                      ...input,
                      author_name: e.target.value,
                    })
                  }
                }
              ></input>
            </li>
            <li>
              <span>メールアドレス / E-mail</span>
              <input
                onChange={
                  (e) => {
                    setInput({
                      ...input,
                      author_email: e.target.value,
                    })
                  }
                }
                value={input.author_email}
              ></input>
            </li>
            <li>
              <span>メッセージ / Message</span>
              <textarea
                value={input.content}
                onChange={
                  (e) => {
                    setInput({
                      ...input,
                      content: e.target.value,
                    })
                  }
                }
              ></textarea>
            </li>
          </ul>
          <Statuses>
            <Button
              className={
                isButtonActive && 'active'
              }
              onClick={
                (e) => {
                  isButtonActive && send(input)
                  setSending(true);
                }
              }
              isSending={isSending}
            >
              Send
            </Button>
            {msg && <MsgBox>{msg}</MsgBox>}
          </Statuses>
        </ContactForm>
      }
    </MainContents>
  );


}

const ContactForm = styled.form`
  margin: 80px 0 0 0;
  padding: 0 0 0 0;
  ul{
    li{
      /* display:flex; */
      margin: 0 0 30px 0;
      span{
        display:block;
        width: 240px;
        margin: 0 0 10px 0;
        font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
      }

      input,textarea{
        -webkit-appearance: none;
        width: 100%;
        border: 1px solid #eee;
        background: #eee;
        border-radius: 0;
        padding: 12px;
        font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
        font-size: 20px;
        line-height:1.5;
        letter-spacing:1px;
        transition: all 0.2s;
        &:hover{
          outline: none;
          -webkit-appearance: none;
          border: 1px solid ${CP.red};
          background: #f3f3f3;
        }
        &:focus{
          outline: none;
          -webkit-appearance: none;
          border: 1px solid ${CP.red};
          background: #fafafa;
        }
      }
      textarea{
        height: 200px;
      }

    }
  }
  
  @media (max-width: 768px){
    margin: 8px 0 0 0;
    padding: 5.4vw;
    ul{
      li{
        /* display:flex; */
        margin: 0 0 20px 0;
        span{
          display:block;
          width: 240px;
          margin: 0 0 6px 0;
          font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
          font-size:12px;
        }

        input,textarea{
          box-sizing: border-box;
          padding: 6px 10px 4px 8px;
          font-size: 16px;
        }
        textarea{
          height: 200px;
        }

      }
    }
  }
`

const Statuses = styled.section`
    display: flex;
`

const Button = styled.p`
    font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
    border: 1px solid #eee;
    background: #eee;
    width: 40px;
    padding: 12px 20px 11px 20px;
    text-align:center;
    color: #888;
    font-size: 20px;
    position: relative;
    right: 0;
    /* cursor: pointer; */
    cursor: ${props => props.isSending ? 'progress' : 'pointer'};
    &.active{
      color: ${CP.white};
      border: 1px solid ${CP.red};
      background: ${CP.red};
    }
    @media (max-width: 768px){
      font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
      padding: 16px 20px 15px 20px;
      text-align:center;
      color: #888;
      font-size: 12px;
      position: relative;
      right: 0;
      cursor: ${props => props.isSending ? 'progress' : 'pointer'};
      &.active{
        color: ${CP.white};
        border: 1px solid ${CP.red};
        background: ${CP.red};
      }
    }
`

const MsgBox = styled.p`
    font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
    padding: 12px 20px 11px 20px;
    font-size: 20px;
    @media (max-width: 768px){
      font-family:  "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
      padding: 16px 20px 15px 20px;
      font-size: 12px;
    }`


export default Contact;