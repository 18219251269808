import React from 'react'
import styled from "styled-components"
import { useLocation } from 'react-router-dom'

import { ColorPalette as CP } from "./BaseStyles.js"
import Menu from './Menu.js'
import { PageList } from './Pager.js'



const Header = () => {
  const { pathname } = useLocation();
  const isBlog = pathname.match(/\/blogs\/[0-9]{1,}/)

  return (
    <HeaderWrap isAnimate={null}>
      {isBlog && <LanguageInfo>日／Eng</LanguageInfo>}
      <Menu
        menulist={PageList}
      />
    </HeaderWrap>
  )
}



const HeaderWrap = styled.header`
      position:sticky;
      top:0;
      width: 100vw;
      height: 60px;
      border-bottom: 1pt solid ${CP.red};
      top:0;
      left:0;
      background: ${CP.white};
      z-index:5;
      @media (max-width: 768px){
        height: 52px;
        border-width: 0.7pt;
      }
      `

const LanguageInfo = styled.p`
      color: ${CP.red};
      font-size:22px;
      font-family: "Century-Schoolbook-Reg", "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
      height: 22px;
      margin: auto 0;
      top: 5px;
      bottom:0;
      left: 16px;
      position: absolute;
      @media (max-width: 768px){
        display: none;
      }
`

export default Header;