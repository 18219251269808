import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Reset } from 'styled-reset'
import LoadingAnimation from "./LoadingAnimation.js"

import Header from "./Header.js"
import Footer from "./Footer.js"
import MainContentRouter from './MainContentRouter.js'
import BaseStyles from "./BaseStyles.js"

function App() {
  const [loaded, setLoaded] = useState(false)
  const [animate, setAnimate] = useState(true)

  useEffect(() => {
    const isTopPage = window.location.href.replace(window.location.origin, '') == '/'
    setTimeout(() => {
      setAnimate(false)
      setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }, (isTopPage ? 0 : 1000))
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <LoadingAnimation loaded={loaded} animate={animate} >
          <div>
            <Reset />
            <BaseStyles />
            <Header />
            <MainContentRouter />
            <Footer />
          </div>
        </LoadingAnimation>
      </BrowserRouter>
    </div>
  );
}


export default App;