import styled, { createGlobalStyle, css } from "styled-components";

import DFPKyoKaShoW3_woff_full from './fonts/DFPKyoKaSho-W3/DFPKyoKaSho-W3.woff'

import MSPMincho_woff_Full from './fonts/ms-pmincho/ms-pmincho.woff'

import CenturySchoolbookRegFull from './fonts/Century-Schoolbook/Century-Schoolbook-Std-Regular.woff'


export const ColorPalette = {
  red: '#D02B24',
  black: '#303030',
  white: '#FFFFFF'
}

export const MediaQueries = {
  mini: 640,
  midium: 1024,
  max: 1200
}


const BaseStyles = createGlobalStyle`
  
  @font-face {
    font-family: "MS-P-Mincho";
    src: url(${MSPMincho_woff_Full}) format("woff");
    font-display: block;
  }

  @font-face {
    font-family: "Century-Schoolbook-Reg";
    src: url(${CenturySchoolbookRegFull}) format("woff");
    font-display: block;
  }

  body *{
    font-family: "Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
  }
  a{
    color:${ColorPalette.red};
    text-decoration:none;
    -webkit-tap-highlight-color:#fff0;
  }
`;


export const LogoTextWrap = styled.span`
  color: ${ColorPalette.red};
  font-size:${props => props.fontSize.max};
  font-family: "Century-Schoolbook-Reg", serif;

  @media (max-width:${MediaQueries.max}px){
    font-size:${props => props.fontSize.max};
  }
  @media (max-width:${MediaQueries.midium}px){
    font-size:${props => props.fontSize.midium};
  }
  @media (max-width:${MediaQueries.mini}px){
    font-size:${props => props.fontSize.mini};
  }
`

export const Outline = styled.span`
  font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho",serif;
  font-size:${props => props.fontSize.max};
  -webkit-text-stroke: calc( ${props => props.fontSize.max} / 40 ) ${ColorPalette.red};
  @media (max-width:${MediaQueries.max}px){
    -webkit-text-stroke: calc( ${props => props.fontSize.max} / 40 ) ${ColorPalette.red};
    font-size:${props => props.fontSize.max};
  }
  @media (max-width:${MediaQueries.midium}px){
    -webkit-text-stroke: calc( ${props => props.fontSize.midium} / 40 ) ${ColorPalette.red};
    font-size:${props => props.fontSize.midium};
  }
  @media (max-width:${MediaQueries.mini}px){
    -webkit-text-stroke: calc( ${props => props.fontSize.mini} / 40 ) ${ColorPalette.red};
    font-size:${props => props.fontSize.mini};
  }

  color: ${ColorPalette.white};
  
`

export const createOutline = (props) => {
  return css`
    font-size:${props => props.outlinedLetter.max};
    -webkit-text-stroke: calc( ${props => props.outlinedLetter.max} / 40 ) ${ColorPalette.red};
    font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho",serif;

    @media (max-width:${MediaQueries.max}px){
      -webkit-text-stroke: calc( ${props => props.outlinedLetter.max} / 40 ) ${ColorPalette.red};
      font-size:${props => props.outlinedLetter.max};
    }
    @media (max-width:${MediaQueries.midium}px){
      -webkit-text-stroke: calc( ${props => props.outlinedLetter.midium} / 40 ) ${ColorPalette.red};
      font-size:${props => props.outlinedLetter.midium};
    }
    @media (max-width:${MediaQueries.mini}px){
      -webkit-text-stroke: calc( ${props => props.outlinedLetter.mini} / 40 ) ${ColorPalette.red};
      font-size:${props => props.outlinedLetter.mini};
    }
    color: ${ColorPalette.white};
  `
}


const ImageInArticleStyles4Blog = () => {
  return css`
      width: 90vw;
      object-fit: cover;
      position: relative;
      left:-9.7vw;
      @media (max-width: 768px){
        left:0;
        margin: 0 0 0px calc(-11vw - 0.7pt);
      }
  `
}
const ImageInArticleStyles4Page = () => {
  return css`
      width: 100vw;
      margin: 0 calc(-9.5vw - 14px);
      @media (max-width: 768px){
        margin: 0 calc(-5.5vw - 14px);
      }
  `
}

export const Article = styled.section`
  font-family: "Century-Schoolbook-Reg",serif;
  margin: 45px 0 0 0;
  ${props => props.isPage && css`
      padding: 0 calc(5.5vw + 14px);
      `
  }
  @media (max-width: 768px){
    margin: 25px 0 0 0;
    ${props => props.isPage && css`
      padding: 0;
      `
  }
  }
  h1{
    font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho",serif;
    ${props => createOutline(props)}
    margin: 0 0 5px 0;
    @media (max-width: 768px){
      margin: 0 0 5px 5.5vw;
    }
  }
  *{
    font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho",serif;
    line-height: 1.7;
  }
  h2{
    margin: 120px 0 30px 0;
    color: ${ColorPalette.red};
    font-size:33px;
    @media (max-width: 768px){
      margin: 55px 0 30px 0;
      font-size:22px;
    }
  }
  h3{
    margin: 80px 0 30px 0;
    color: ${ColorPalette.red};
    font-size:22px;
    @media (max-width: 768px){
      margin: 55px 0 30px 0;
      font-size:19px;
    }
  }
  h4,h5,h6{
    font-size:18px;
    color: ${ColorPalette.red};
    margin: 0 0 5px 0;
    @media (max-width: 768px){
      margin: 55px 0 30px 0;
      font-size:17px;
    }
  }

  p{
    font-size:14px;
    line-height:1.5;
    box-sizing: border-box;
    width:100%;
    font-size:14px;
    /* margin: 0 0 14px 0; */
    margin: 0 0 11px 0;
    color: ${ColorPalette.black};
    &[lang="ja"]{
      line-height: 1.7;
      +p[lang="en"]{
        margin-top: 11px;
        /* margin-top: 23px; */
        @media (max-width: 768px){
          margin-top: 11px;
          /* margin-top: 18px; */
        }
      }
    }
    &[lang="en"]{
      font-size:16px;
      color: ${ColorPalette.red};
      +p[lang="ja"]{
        margin-top: 11px;
        /* margin-top: 23px; */
        @media (max-width: 768px){
          margin-top: 11px;
          /* margin-top: 18px; */
        }
      }
    }
    &:not([lang]):empty{
      display: none;
    }
    &:not([lang]){
      margin: 0;
      +p[lang="en"],
      +p[lang="ja"]{
        margin-top: 11px;
        /* margin-top: 23px; */
        @media (max-width: 768px){
          margin-top: 11px;
          /* margin-top: 18px; */
        }
      }
    }
      ${props => props.isBlog && css`
      width:80%;
      &[lang="en"]{
        text-align: justify;
        text-align-last: right;
        margin-left: auto;
        /* margin-right: 14px; */
      }
      &[lang="ja"]{
        text-align: left;
        margin-right: auto;
        /* margin-left: 14px; */
      }
    `}
    @media (max-width: 768px){
      &[lang="en"]{
        font-size:14px;
      }
      &[lang="ja"]{
        font-size:12px;
      }
      ${props => props.isBlog && css`
        width:90%;
        &[lang="ja"],
        &[lang="en"]{
          line-height: 1.5;
          /* padding: 0 14px; */
        }
        &[lang="ja"]{
          line-height: 1.7;
        }
      `}
      ${props => props.isPage && css`
        width:100%;
        margin: 0 0 25px 0;
        padding: 0 14px;
        &[lang="ja"],
        &[lang="en"]{
          margin: 0 0 20px 0;
          line-height: 1.5;
        }
        &[lang="ja"]{
          line-height: 1.7;
        }
      `}
    }
  }
  img{
    visibility: visible;
    background: #ff9a9211;
    backdrop-filter: blur(2px);
    height: auto;
    margin: 0 0 50px 0;
    ${props => props.isBlog && ImageInArticleStyles4Blog()}
    ${props => props.isPage && ImageInArticleStyles4Page()}
  }
  @media (max-width:768px){
    >img{
      margin:0 -5.5vw;
    }
  }

  a{
    /* border-bottom: 0.7pt solid ${ColorPalette.black}; */
    text-decoration: underline;
    color:currentColor;
    text-decoration-color: currentColor;
    text-decoration-thickness: 1px;
    padding:0 4px;
    transition: .3s;
    cursor: pointer;
    &:hover{
      text-decoration-thickness: 2px;
      /* border-bottom: 1px solid ${ColorPalette.red}; */
      /* color: ${ColorPalette.red}; */
    }
  }

  strong{
    font-weight: bold;
    padding: 0 1px;
  }
  em{
    font-style: italic;
  }

  blockquote{
    background: #ff9a9211;
    backdrop-filter: blur(2px);
    padding: 20px;
    margin: 0 0 30px 0;
    p{
      margin:0 0 20px 0;
      color: #666;
    }
    p:last-child{
      margin:40px 0 0 0;
      font-style: italic;
      text-align: right;
    }
    cite{
    }
  }

  del{
    color:#888;
  }
  ins{
    background: #ff9a9211;
    padding: 4px 2px 2px 2px;
    text-decoration: none;
  }
  
  ul,
  ol{
    margin: 16px 0;
    li{
      font-size: 14px;
      @media (max-width: 768px){
        font-size:12px;
      }
    }
  }
  
  ul{
    padding: 0 0 0 20px;
    list-style: '- ';
  }
  
  ol{
    padding: 0 0 0 5px;
    counter-reset: liIndex;
    li:before {
      counter-increment: liIndex;
      content: counter(liIndex)'. ';
    }
  }
  
  code{
    font-family: 'SF-Mono','Menlo';
    background: #f0f0f0;
    padding: 2px 6px;
  }
  iframe{
    visibility: visible;
    right: 0;
    left: 0;
    width: 50vw;
    min-width: 400px;
    height: calc(50vw * 0.5625);
    min-height: calc(400px * 0.5625);
    margin: 0 auto;
    display: block;
    @media (max-width: 768px){
      width: 100%;
      min-width: unset;
      /* height: calc((100vw - 5.5vw * 2 - 14px * 2) * 0.5625); */
      height: calc((89vw - 28px) * 0.5625);
      min-height:unset;
    }
  }
  ${props => props.customStyles}

`

export const MenuActiveStyles = (menuIsActive) => {
  return css`
    overflow: ${menuIsActive && 'hidden'} ;
  `
}

export const MainContents = styled.section`
  width: 94vw;
  padding: 50px 4vw 30vh 4vw;
  margin: 0 0 0 5.5vw;
  min-height: calc(100vh - 60px);
  border-left:1pt solid ${ColorPalette.red};
  box-sizing: border-box;
  @media (max-width: 768px){
    border-width: 0.7pt;
    padding: 5.5vw 5.5vw calc(5.5vw + 40px) 5.5vw;
    h1{
      /* margin: 0 0 0 5.5vw; */
    }
  }
  ${props => props.customStyles}
`

export const ArticleHeader = (props) => {
  return css`
    padding: 0 0 0 20px;
    margin: 82px 0 0 0;
    width:calc(82% - 10.15vw);
    
    @media (max-width:1024px) {
      /* padding: 0 0 0 14px; */
      margin: 55px 0 0 0;
    }
    @media (max-width:768px) {
      /* padding: 0 0 0 calc(19px + 5.5vw); */
      margin: 38px 0 0 0;
    }
    div,
    li{
      margin: 0 0 40px 0;
      padding: 0 0 0 0px;
      min-height:128px;
      h2{
        font-size: 33px;
        display: flex;
        span{
          font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho",serif;
          color: ${ColorPalette.red};
          display: block;
          word-break:break-all;
          margin: 0 56px 0 0;
          &+span{
            margin :0 ;
            /* max-width: calc(100% - 45px); */
          }
        }
      }
      div{
        padding: 0 0 0 74px;
        p{
          font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
          padding: 12px 0 0 0;
          color: ${ColorPalette.black};
          font-size:13px;
          line-height: 1.4;
        }
      }
      &:last-child{
        margin: 0 0 0px 0;
      }
      @media (max-width:1024px){
        margin: 0 0 30px 0;
        min-height:100px;
        h2{
          font-size: 26px;
          span{
            margin: 0 44px 0 0;
          }
        }
        div{
          padding: 0 0 0 60px;
          p{
            padding: 12px 0 0 0px;
            font-size:12px;
          }
        }
      }
      @media (max-width:768px){
        margin: 0 0 30px 0;
        min-height:78px;
        h2{
          font-size: 18px;
          span{
            margin: 0 24px 0 0;
          }
        }
        div{
          padding: 0 0 0 34px;
          p{
            padding: 12px 0 0 0px;
            font-size:10px;
          }
        }
      }
    }
    ${props => props.customStyles}
  `
}

export default BaseStyles;




