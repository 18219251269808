import styled, { keyframes } from "styled-components"
import { ColorPalette as CP } from "./BaseStyles.js"
import React, { useEffect } from 'react'

let animationSpeed = 9770

const PreLoaderTop = (props) => {
  window.scrollTo(0, 0);

  useEffect(() => {
    document.body.style.overflow = "hidden"
    document.addEventListener('animationend', () => {
      document.body.style.overflow = "scroll"
      setTimeout(() => {
        props.anime(false)
      }, 100)
    });
  }, [])

  return (
    <LoadAnimation >
      <AnimationBlock.A />
      <AnimationBlock.B.wrap>
        <AnimationBlock.B.left />
        <AnimationBlock.B.right />
      </AnimationBlock.B.wrap>
      <AnimationBlock.C />
      <AnimationBlock.D />
      <AnimationBlock.E.wrap>
        <AnimationBlock.E.slide />
        <AnimationBlock.E.issueLink />
        <AnimationBlock.E.socialLinks />
        <AnimationBlock.E.aboutLink />
      </AnimationBlock.E.wrap>
    </LoadAnimation>
  )
}


const Keyframes = {
  keySeparate: keyframes`
      0%   {}
      7%   {}
      15%  {}
      23%  {}
      30%  {}
      38%  {}
      46%  {}
      53%  {}
      61%  {}
      69%  {}
      76%  {}
      84%  {}
      92%  {}
      100% {}
      `,
  pc: {
    A: keyframes`
      0%   {width: 0vw; height: 50vh;}
      7%   {width: 0vw; height: 50vh;}
      15%  {width: 100vw;}
      23%  {width: 100vw; height: 50vh;}
      30%  {width: 100vw; height: 60px;}
      `,
    B: {
      left: keyframes`
          0%   {width: 0px; height: 0px; left:22.3%; border-bottom: none}
          38%  {width: 0px; height: 0px; left:22.3%; border-bottom: none}
          46%  {width: 0px; height: 146.8vw ;left:22.3%; border-bottom: 1pt solid ${CP.white};}
          53%  {width: 22.3%; height: 146.8vw; left:0px; }
          `,
      right: keyframes`
          0%   {width: 0px; height: 0px; right:22.3%;}
          38%  {width: 0px; height: 0px; right:22.3%;}
          46%  {width: 0px; height: 146.8vw ; right:22.3%;}
          53%  {width: 22.3%; height: 155.5vw; right:0px;}
          `,
    },
    C: keyframes`
      0%   {width: 0px; height:0px;border-width:0}
      45%  {border-width:0vw}
      46%  {width: 0px; height:0px;border-width:1pt}
      53%  {width: 55.4%;height: 11.7vw;border-top: 1pt solid ${CP.white};border-left: 1pt solid ${CP.white};}
      `,
    D: keyframes`
      0%   {width: 0px; height:0px;border-width:0}
      52%  {border-width:0;}
      53%  {width: 0px; height:0px;border-width:1pt}
      61%  {width: calc(22.3% + 1pt);height: calc(20vw + 1pt);border-top: 1pt solid ${CP.white};border-left: 1pt solid ${CP.white};}
      `,
    E: {
      commons: keyframes`
          0%   {transform:scale(0,0);border-width:0;}
          61%  {transform:scale(0,0)}
          68%  {border: 1px solid ${CP.white};}
          69%  {transform:scale(1,1)}
          `,
      slide: keyframes`
          0%   {transform:translate(calc(18.9% - 6px), 0px) scale(0,0);border-width:0;}
          61%  {transform:translate(calc(18.9% - 6px), 0px) scale(0,0)}
          68%  {border: 1px solid ${CP.white};}
          69%  {transform:translate(calc(18.9% - 6px), 0px) scale(1,1)}
          `,
    },
    wrap: keyframes`
      0%   {opacity:1}
      92%  {opacity:1}
      100% {opacity:0}
      `,
  },

  mobile: {
    A: keyframes`
      0%   {width: 0vw; height: 45vh;}
      7%   {width: 0vw; height: 45vh;}
      15%  {width: 100vw;}
      23%  {width: 100vw; height: 45vh;}
      30%  {width: 100vw; height: 52px;}
      `,
    B: {
      left: keyframes`
          0%   {width: 0px; height: 0px; left:22.3%; border-bottom: none}
          38%  {width: 0px; height: 0px; left:22.3%; border-bottom: none}
          46%  {width: 0px; height: 147.6vw ;left:22.3%; border-bottom: 1pt solid ${CP.white};}
          53%  {width: 22.3%; height: 147.6vw; left:0px; }
          `,
      right: keyframes`
          0%   {width: 0px; height: 0px; right:16%;}
          38%  {width: 0px; height: 0px; right:16%;}
          46%  {width: 0px; height: 147.6vw ; right:16%;}
          53%  {width: 16%; height: 155.5vw; right:0px;}
          `,
    },
    C: keyframes`
      0%   {width: 0px; height:0px;border-width:0}
      45%  {border-width:0vw}
      46%  {width: 0px; height:0px;border-width:1pt}
      53%  {width: 61.8%;height: 11.7vw;border-top: 1pt solid ${CP.white};border-left: 1pt solid ${CP.white};}
      `,
    D: null,
    E: {
      slide: keyframes`
          0%   {transform:translate(calc(18.9% - 6px), 0px) scale(0,0);border-width:0;}
          61%  {transform:translate(calc(18.9% - 6px), 0px) scale(0,0)}
          68%  {border: 1px solid ${CP.white};}
          69%  {transform:translate(calc(18.9% - 6px), 0px) scale(1,1)}
          `,
      wrap: null
    }
  }
}



const AnimationBlock = {
  A: styled.div`
      top:0;
      left: 0;
      right:0;
      width: 100vw;
      height: 60px;
      margin: 0 auto;
      border-bottom: 1pt solid ${CP.white};
      animation: ${Keyframes.pc.A} ${animationSpeed}ms;
      @media (max-width:768px){
        height: 52px;
        animation: ${Keyframes.mobile.A} ${animationSpeed}ms;
      }
  `,
  B: {
    wrap: styled.div`
            width: 100vw;
            display: flex;
            justify-content: space-between;
            >div{
              position: absolute;
            }
    `,
    left: styled.div`
            width: 22.3%;
            left:0px;
            height: 146.8vw;
            border-right: 1pt solid ${CP.white};
            border-bottom: 1pt solid ${CP.white};
            animation: ${Keyframes.pc.B.left} ${animationSpeed}ms;
            @media (max-width:768px){
              height: 147.6vw;
              animation: ${Keyframes.mobile.B.left} ${animationSpeed}ms;
            }
      `,
    right: styled.div`
            width: 22.3%;
            right:0px;
            height: 155.5vw;
            border-left: 1pt solid ${CP.white};
            animation: ${Keyframes.pc.B.right} ${animationSpeed}ms;
            @media (max-width:768px){
              width: 16%;
              animation: ${Keyframes.mobile.B.right} ${animationSpeed}ms;
            }
    `
  },
  C: styled.div`
      width: 55.4%;
      height: 11.7vw;
      top:calc(147vw + 60px);
      left: 22.3%;
      box-sizing: border-box;
      position: absolute;
      border-top: 1pt solid ${CP.white};
      border-left: 1pt solid ${CP.white};
      animation: ${Keyframes.pc.C} ${animationSpeed}ms;
      @media (max-width:768px){
        width: 61.8%;
        top:calc(147.7vw + 52px);
        animation: ${Keyframes.mobile.C} ${animationSpeed}ms;
      }
      `,
  D: styled.div`
      width: calc(22.3% + 1pt);
      height: calc(20vw + 1pt);
      top:calc(155.7vw + 60px);
      left: calc(77.7% - 1pt);
      box-sizing: border-box;
      position: absolute;
      border-top: 1pt solid ${CP.white};
      border-left: 1pt solid ${CP.white};
      animation: ${Keyframes.pc.D} ${animationSpeed}ms;
      @media (max-width:768px){
        width: calc(16% + 1pt);
        top:calc(155.6vw + 52px);
        left: calc(84% - 1pt);
      }
      `,
  E: {
    wrap: styled.div`
            width: 100%;
            padding: 0 22.3%;
            box-sizing: border-box;
            >div{
              border: 1px solid ${CP.white};
              background: ${CP.red};
              box-sizing: border-box;
            }
            @media (max-width:768px){
              padding: 0 16% 0 22.3%;
            }
    `,
    slide: styled.div`
            width:calc(118.9% - 0.6vw);
            height: 35.4vw;
            position: relative;
            margin: 10.4vw 0px 0px;
            left: calc(-8% + 0.4vw);
            transform: translate(calc(18.9% - 6px), 0px) scale(1,1);
            z-index: 2;
            box-sizing: border-box;
            animation: ${Keyframes.pc.E.slide} ${animationSpeed}ms;
            @media (max-width:768px){
              width:calc(105.55% - 1pt);
              height: 36.4vw;
              left: -8%;
              animation: ${Keyframes.mobile.E.slide} ${animationSpeed}ms;
            }
    `,
    issueLink: styled.div`
            width: 69%;
            height: 26.5vw;
            overflow: hidden;
            position: relative;
            margin: 33.3vw auto 0px;
            left: 0px;
            right: 0px;
             transform:scale(1,1);
              animation: ${Keyframes.pc.E.commons} ${animationSpeed}ms;
            `,
    socialLinks: styled.div`
            width: 5.966vw;
            height: calc(33vw + 1pt);
            border: 1px solid rgb(208, 43, 36);
            position: relative;
            margin: 12.5vw 0px 0px;
            left: -2.8vw;
             transform:scale(1,1);
              animation: ${Keyframes.pc.E.commons} ${animationSpeed}ms;
              @media (max-width:768px){
                width:6.266vw;
                height: 33.3vw;
              }
            `,
    aboutLink: styled.div`
            width: 17.9vw;
            height: calc(25vw + 1pt);
            border: 1px solid rgb(208, 43, 36);
            position: relative;
            top: -12.833vw;
            left: 43.7vw;
            padding: 1vw;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;
             transform:scale(1,1);
              animation: ${Keyframes.pc.E.commons} ${animationSpeed}ms;
              @media (max-width:768px){
                height: 25.4vw;
                top: -12.57vw;
                left: 50.1vw;
              }
            `,
  }
}

const LoadAnimation = styled.div`
  background: ${CP.red};
  height:200vh;
  width:100vw;
  position: fixed;
  z-index:10;
  opacity:0;
  top:0;
  animation: ${Keyframes.pc.wrap} ${animationSpeed}ms;
`

export default PreLoaderTop;
