import { Helmet } from 'react-helmet';
import React from 'react';
import { useLocation } from 'react-router-dom'

const Seo = ({ title, description, pathSlug, ogImage }) => {
  const { pathname } = useLocation()

  const url = `https://imediea.net${pathname || '/' + pathSlug}`
  const titleOut = title + " | i + med(i/e)a mag"
  return (
    <Helmet
      htmlAttributes={{ lang: 'jp' }}
      title={titleOut}
      links={
        [
          {
            rel: 'canonical',
            href: url,
          },
        ]
      }
      meta={
        [
          { name: 'description', content: description || '' },
          { property: 'og:title', content: titleOut || '' },
          { property: 'og:url', content: url || '' },
          { name: 'twitter:card', content: 'summary_large_image' },
        ]
      }


    />
  );
}
export default Seo;
