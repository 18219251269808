import React from 'react'
import styled, { keyframes } from "styled-components";
import { ColorPalette as CP } from "./BaseStyles.js"


const LoadingAnimation = (props) => {
  return (
    <>
      {!props.loaded &&
        <LoadingAnimationMain className={props.animate && 'active'}>
          <div />
        </LoadingAnimationMain>}
      {props.children}
    </>
  );
}

const LoadingAnimationKeyframes = keyframes`
  0%{
    width: 0;
    right: 100vw;
    left: 0;
  }
  50%{
    width: 100vw;
    left: 0;
    right: 0;
  }
  100%{
    width: 0;
    left: 100vw;
    right: 0;
  }
`

const LoadingAnimationMain = styled.div`
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-Available;
  background: ${CP.red};
  opacity: 0;
  transition: 1s;
  position: fixed;
  top: 0;
  z-index: 10;
  &.active{
    opacity:1;
  }
  div{
    position: absolute;
    height: 60px;
    border-bottom: 1pt solid #fff;
    animation: ${LoadingAnimationKeyframes} 10s ease infinite;
    @media (max-width:768px){
      border-width: 0.7pt;
      height: 52px;
    }
  }
  
`

export default LoadingAnimation