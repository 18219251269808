import styled from 'styled-components';
import BaseStyles, { ColorPalette as CP } from "./BaseStyles.js"

const Footer = () => {
  return (
    <FooterWrap >
      <p>
        <span>2021. 8. 15.</span>
        <span>i + med(i/e)a mag_　all right reserved.</span>
      </p>
    </FooterWrap >
  );
}

const FooterWrap = styled.section`
  width: 100vw;
  height:120px;
  padding: 2pt 0 0 0;
  border-top: 1pt solid ${CP.red};
  background: ${CP.white};
  &::before {
    content:'';
    border-top: 1pt solid ${CP.red};
    width: 100vw;
    display: block;
  }
  p{
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
      font-family: "ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
      color:${CP.red};
      font-size:14px;
    }
  }
  @media (max-width:768px){
    border-width: 0.7pt ;
    height:117px;
    padding: 1pt 0 0 0;
    &::before {
      border-width: 0.7pt;
    }
    p{
      margin: 6px 0 0 0;
      span{
        font-family: "ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
        color:${CP.red};
        font-size: 10px;
      }
    }

  }
  `

export default Footer;