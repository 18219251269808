import React, { useEffect, useState } from 'react';
import styled, { css } from "styled-components"
import {
  ColorPalette as CP,
  Article,
  MainContents,
  Outline
} from "./BaseStyles.js"
import { LogoText } from "./Pager.js"
import SEO from './SEO.js'

const Issues = () => {
  const [postData, setPostData] = useState({})
  useEffect(() => {
    fetch(`https://imediea.net/wp/wp-json/wp/v2/issues/?order=asc`)
      .then((response) => response.json())
      .then((post) => {
        setPostData({
          loaded: true,
          data: post
        })
      })
  }, [])

  const out = postData.loaded && postData.data.map((article, i) => {
    const excerpt = article.excerpt.rendered.replace(/<p>.*<a class="more-link" .*<\/a><\/p>/gi, article.title.rendered)
    return (
      <ArticleWrap key={i}>
        <h1>
          <Outline fontSize={
            {
              max: '55px',
              midium: '42px',
              mini: '28px'
            }
          }>
            {article.title.rendered}
          </Outline>
        </h1>
        <ExcerptWrap dangerouslySetInnerHTML={{ __html: excerpt }} />
        <Article
          dangerouslySetInnerHTML={{ __html: article.content.rendered }}
          outlinedLetter={{
            max: '55px',
            midium: '42px',
            mini: '28px'
          }}
          customStyles={
            css`
              
              p.en{
                text-align: left;
              }
            `
          }
          isPage={true}
        />
      </ArticleWrap>
    )
  })
  return (
    <MainContents
      customStyles={
        css`
                border-left:none;
                margin: 0 0 0 0;
                width:100%;
            `
      }
    >
      <SEO
        title={`Issues`}
        description={postData.loaded && postData.data[postData.data.length - 1].excerpt.rendered.replace(/<p>.*<a class="more-link" .*<\/a><\/p>/gi, postData.data[postData.data.length - 1].title.rendered).replace(/<p>(.*)<\/p>/gi, '$1')}
        pathSlug={`Issues`}
        ogImage={null}
      />
      {postData.loaded && out}
    </MainContents>
  );
  // } else {
  // return (
  //   <p>now loading</p>
  // )
  // }
}

const ArticleWrap = styled.section`
  margin: 0 0 80px 0;
  &+section{
    padding: 80px 0 0 0;
    border-top: 1pt solid ${CP.red};
  }
`
const ExcerptWrap = styled.p`
  font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
  padding: 12px 0 0 0;
  color: ${CP.black};
  font-size:13px;
  line-height: 1.4;
  @media screen and (max-width:768px){
    /* margin: 0 0 0 5.5vw; */
  }
`




export default Issues;