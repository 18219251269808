import styled, { css } from "styled-components"
import React, { useEffect, useState } from 'react'
import SEO from './SEO.js'
import {
  ColorPalette as CP,
  Article,
  MainContents,
  Outline
} from "./BaseStyles.js"
const Manual = () => {

  const [pageData, setPageData] = useState([])
  useEffect(() => {
    const url = 'https://imediea.net/wp/wp-json/wp/v2/pages/267'
    fetch(url)
      .then((response) => response.json())
      .then((data) => {

        setPageData(
          {
            pageData: data,
            loaded: true,
          }
        )
      })
  }, [])

  const loadingMessage = `<p>now loading</p>`

  return (
    <MainContents
      customStyles={
        css`
                border-left:none;
                margin: 0 0 0 0;
                width:100%;
            `
      }
    >
      <SEO title={"Manual"} />
      <h1>
        <Outline fontSize={
          {
            max: '55px',
            midium: '42px',
            mini: '28px'
          }
        }>
          {pageData.loaded && pageData.pageData.title.rendered}
        </Outline>
      </h1>
      {pageData.loaded && <ExcerptWrap dangerouslySetInnerHTML={{ __html: pageData.pageData.the_post_data_includes_raw.excerpt.rendered }} />}
      {pageData.loaded && <Article
        dangerouslySetInnerHTML={{ __html: pageData.pageData.content.rendered.replace(/<p>(\s){0,}<\/p>/g, '') || loadingMessage }}
        outlinedLetter={{
          max: '55px',
          midium: '42px',
          mini: '28px'
        }}
        customStyles={
          css`
              margin: 45px 0 0 0;
              p.en{
                text-align: left;
              }
              h2:not([id=""]){
                padding: 100px 0 0 0;
                margin: 20px 0 0 0;
              }
              h3.h2uh3ttl{
                text-decoration: underline dashed 3px;
                padding: 100px 0 0 0;
                margin: -30px 0 0 0;
                &+h1,
                &+h2,
                &+h3,
                &+h4,
                &+h5,
                &+h6{
                  margin-top:10px;
                }
              }
              img.forManual{
                visibility: visible;
                width: 100%;
                margin: 0 0 50px 0;
              }
              span.warn{
                background: #ffe657;
              }
            `
        }
        isPage={true}
      />}
      {pageData.loaded && console.log(pageData.pageData.content.rendered)}
    </MainContents>
  )
}


const ExcerptWrap = styled.p`
  font-family:"Century-Schoolbook-Reg","ＭＳ Ｐ明朝", "MS PMincho","MS-P-mincho", serif;
  padding: 12px 0 0 0;
  color: ${CP.black};
  font-size:13px;
  line-height: 1.4;
  @media screen and (max-width:768px){
    margin: 0 0 0 5.5vw;
  }
`

export default Manual;