import React, { useEffect, useState } from 'react';
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import NotFound from "./NotFound.js"
import SEO from "./SEO.js"


import { ColorPalette as CP, Outline, Article, MainContents, ArticleHeader } from "./BaseStyles.js"
import { LogoText } from "./Pager.js"


import Posts from './Posts.js';

const Blogs = (props) => {
  const postId = props.match.params.id
  const [postData, setPostData] = useState([])

  useEffect(() => {
    fetch(`https://imediea.net/wp/wp-json/wp/v2/blogs_talks/${postId}`)
      .then((response) => response.json())
      .then((postData) => {
        if (postData.status !== undefined) {
          setPostData({
            loaded: true,
            data: postData
          })
        } else {
          setPostData({
            loaded: true,
            data: null
          })
        }

      })
  }, [postId])



  if (postData.loaded && postData.data !== null) {
    return (
      <MainContents>
        <SEO
          title={postData.data.title.rendered}
          description={postData.data.the_post_data_includes_raw.excerpt.raw.replace(/\r?\n/g, "")}
          pathSlug={`blogs/${postId}`}
          ogImage={null}
        />
        <h1>
          <Link to={'/blogs'}>
            <LogoText
              fontSize={
                {
                  max: '55px',
                  midium: '42px',
                  mini: '24px'
                }
              }
            /><Outline
              fontSize={
                {
                  max: '55px',
                  midium: '42px',
                  mini: '24px'
                }
              }
            > - blogs / talks</Outline>
          </Link>
        </h1>
        <article>
          <ArticleTitleWrap>
            <div>
              <h2><span dangerouslySetInnerHTML={{ __html: postData.data.title.rendered }} /></h2>
              <div>
                <p dangerouslySetInnerHTML={{ __html: postData.data.the_post_data_includes_raw.excerpt.raw.replace(/\r?\n/g, "<br />") }} />
              </div>
            </div>
          </ArticleTitleWrap>
          <Article
            dangerouslySetInnerHTML={{ __html: postData.data.content.rendered }}
            outlinedLetter={{
              max: '55px',
              midium: '42px',
              mini: '24px'
            }}
            customStyles={
              css`
              `
            }
            isBlog={true}
          ></Article>
        </article>
      </MainContents >
    );
  } else if (postData.data === null) {
    return (
      <NotFound />
    )
  } else {
    return (
      <MainContents>
        <h1>
          <Link to={'/blogs'}>
            <LogoText
              fontSize={
                {
                  max: '55px',
                  midium: '42px',
                  mini: '24px'
                }
              }
            /><Outline
              fontSize={
                {
                  max: '55px',
                  midium: '42px',
                  mini: '24px'
                }
              }
            > - blogs / talks</Outline>
          </Link>
        </h1>
      </MainContents>
    )
  }

}

const ArticleTitleWrap = styled.div`
  ${ArticleHeader()}
  padding: 0 0 0 0px;
  width:100%;
  &>div{
    min-height: 0px;
    &>div{
      padding: 0;
      min-height:68px;
    }
  }
`

export const BlogsList = () => {
  return (
    <MainContents>
      <SEO
        title={`blogs / talks`}
        description={null}
        pathSlug={`blogs`}
        ogImage={null}
      />
      <Posts titleText={'blogs / talks'} />
    </MainContents>
  )
}
export default Blogs;