import React, { useEffect, useState } from 'react'
import { ColorPalette as CP, Outline, LogoTextWrap } from "./BaseStyles.js"
import Top from './Top.js';
import About from './About.js';
import Issues from './Issues.js';
import Blogs from './Blogs.js';
import Contact from './Contact.js';



export const PageList = [
  {
    pageTitle: 'Top',
    link: '/',
    component: <Top />,
    inMenu: false,
  },
  {
    pageTitle: 'about ( i ) ',
    link: '/about',
    component: <About />,
    external: false,
    inMenu: true,
  },
  {
    pageTitle: 'issues',
    link: '/issues',
    component: <Issues />,
    external: false,
    inMenu: true,
  },
  {
    pageTitle: 'blogs / talks',
    link: '/blogs',
    component: <Blogs />,
    external: false,
    inMenu: true,
  },
  {
    pageTitle: 'online shop',
    link: 'http://store.imediea.net/',
    external: true,
    inMenu: true,
  },
  {
    pageTitle: 'contact',
    link: '/contact',
    component: <Contact />,
    external: false,
    inMenu: true,
  }
]

export const LogoText = (props) => {
  return (
    <LogoTextWrap {...props} >i <Outline {...props}>+</Outline> med<Outline {...props}>(i/</Outline>e)<Outline {...props}>a</Outline></LogoTextWrap>
  )
}


const Pager = () => {
  return null;
}


export default Pager;