import styled, { css } from "styled-components"
import React, { useEffect, useState } from 'react'
import { Outline, ArticleHeader } from "./BaseStyles.js"

import { Link } from 'react-router-dom'

import { LogoText } from "./Pager.js"


const Posts = (props) => {
  const [gotPosts, setPosts] = useState([])
  useEffect(() => {
    const url = 'https://imediea.net/wp/wp-json/wp/v2/blogs_talks/'
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setPosts({
          loaded: true,
          data
        })
      })
  }, [])
  if (gotPosts.loaded) {
    let out = gotPosts.data.map((post, i) => {
      return (
        <ArticleTitle key={i}>
          <Link
            to={`/blogs/${post.id}`}
          >
            <h2>
              <span>-</span><span dangerouslySetInnerHTML={{ __html: post.the_post_data_includes_raw.title.raw }} />
            </h2>
            <div>
              <p dangerouslySetInnerHTML={{ __html: post.the_post_data_includes_raw.excerpt.raw.replace(/\r?\n/g, "<br />") }} />
            </div>
          </Link>
        </ArticleTitle>
      )
    })
    return (
      <div>
        <h1>
          <Link to={'/blogs'}>
            <LogoText
              fontSize={
                {
                  max: '55px',
                  midium: '42px',
                  mini: '24px'
                }
              } />
            <Outline
              fontSize={
                {
                  max: '55px',
                  midium: '42px',
                  mini: '24px'
                }
              }
            > - {props.titleText}</Outline>
          </Link>
        </h1>
        <ArticleTitleWrap customStyles={() => {
          return css`
          `
        }}>
          {out}
        </ArticleTitleWrap>
      </div>
    )
  } else {
    return (
      <div>
        <h1>
          <Link to={'/blogs'}>
            <LogoText fontSize={
              {
                max: '55px',
                midium: '42px',
                mini: '24px'
              }
            } /><Outline fontSize={
              {
                max: '55px',
                midium: '42px',
                mini: '24px'
              }
            }> - {props.titleText}</Outline>
          </Link>
        </h1>
        <ArticleTitleWrap>

        </ArticleTitleWrap>
      </div>
    )
  }


}
const ArticleTitleWrap = styled.ul`
  ${ArticleHeader()}
  padding: 0 0 0 15px;
    margin: 82px 0 0 0;
    width: calc(90%);
`
const ArticleTitle = styled.li``


export default Posts