import React, { useEffect } from 'react'
import { Route, useLocation, Switch } from 'react-router-dom'

import Top from './Top.js';
import About from './About.js';
import Issues from './Issues.js';
import Blogs, { BlogsList } from './Blogs.js';
import Contact from './Contact.js';
import Manual from './manual.js';
import NotFound from "./NotFound.js"

const MainContentRouter = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])
  return (
    <main>
      <Switch>
        <Route path={'/'} component={Top} exact />
        <Route path={'/about'} component={About} />
        <Route path={'/manual'} component={Manual} />
        <Route path={'/issues'} component={Issues} />
        <Route path={'/blogs'} component={BlogsList} exact />
        <Route path={'/blogs/:id'} component={Blogs} />
        <Route path={'/contact'} component={Contact} />
        <Route component={NotFound} exact />
      </Switch>
    </main>
  )
}


export default MainContentRouter;