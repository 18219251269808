import styled from "styled-components"
import { ColorPalette as CP } from "./BaseStyles.js"
import { topImageLoader as IL } from "./Top.js"
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'

const Slider = (props) => {
  const [topImagesContents, setTopImagesContents] = useState(''); // トップ画像、About文章とかとか
  const [loaded, setloaded] = useState(false); // トップ画像、About文章とかとか
  const [activeImage, setActiveImage] = useState(0);
  const displayTime = Number(props.displayTime ?? 2000)
  const switchingTime = props.switchingTime ?? 0.5

  useEffect(() => {
    IL(2)
      .then((img) => {
        setTopImagesContents(img.slice(0, img.length - 1))
        setloaded(true)
      })
  }, [])

  let timeout;

  useLayoutEffect(() => {
    timeout = setInterval(() => {
      setActiveImage(activeImage => activeImage + 1)
    }, displayTime + (activeImage == 0 ? displayTime : 0))
    return _ => clearInterval(timeout)
  }, [activeImage])

  const slideImage = !loaded || topImagesContents.map((content, i) => {
    return (
      <div className={'SlideImageWrap'} key={i}>
        <Link to={'./issues'} >
          <img {...content} />
        </Link>
      </div>
    )
  })
  const dots = !loaded || topImagesContents.map((_, i) => {
    return (
      <Dot
        key={i}
        onClick={
          _ => {
            setActiveImage(i)
            clearInterval(timeout)
          }
        }
        activeImageNum={topImagesContents.length && activeImage % topImagesContents.length}
      />
    )
  })


  return (
    <SliderWrap>
      <SliderImgWrap
        activeImageNum={topImagesContents.length && activeImage % topImagesContents.length}
        switchingTime={switchingTime}
      >
        {slideImage}
      </SliderImgWrap>
      {slideImage && <Dots>
        {dots}
      </Dots>}
    </SliderWrap>
  )
}

export default Slider;

const SliderWrap = styled.div`
    width: 100%;
    height: 100%;
`
const Dots = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    justify-content:flex-end;
    position: relative;
    right: 10px;
    margin: 16px 10px 0 0;
    padding: 0;
    box-sizing: border-box;
    @media (max-width:768px){
      margin: 6px 0px 0 0;
      right: -8px;
    }
`
const Dot = styled.span`
    height: 13px;
    width: 13px;
    border-radius: 50%;
    margin: 0 0 0 12px;
    border: 1px solid ${CP.red};
    cursor: pointer;
    background: ${CP.white};
    &:nth-child(${props => props.activeImageNum + 1}){
      background: ${CP.red};
    }
    @media (max-width:768px){
      height: 4px;
      width: 4px;
      /* border-radius: 4px; */
      border: 1px solid ${CP.red};
    }
`


const SliderImgWrap = styled.div`
    width: 100%;
    display: flex;
    overflow: hidden;
    height:100%;
    div{
      min-width: 100%;
      position: relative;
      left: calc(-100% * ${props => props.activeImageNum});
      transition: all ${props => props.switchingTime / 1000}s;
      img{
        height:100%;
        width: 100%;
        object-fit: cover;
        background: ${CP.white};
      }
    }
  `