import styled from "styled-components"
import {
  ColorPalette as CP,
  Outline,
  MediaQueries
} from "./BaseStyles.js"
import React from 'react'



const NotFound = () => {
  return (
    <NotFoundWrap>
      <p>
        <Outline
          fontSize={
            {
              max: '30px',
              midium: '30px',
              mini: '30px',
            }
          }
        >
          404
        </Outline>
      </p>
      <p>Not Found...</p>
    </NotFoundWrap >
  )
};

export default NotFound;


const NotFoundWrap = styled.div`
  height:40vh;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  display:flex;
  font-family: "Century-Schoolbook-Reg", "ＭＳ Ｐ明朝", "MS PMincho","MS-P-Mincho", serif;
  color: ${CP.red};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding:  0 0 6.25vw 5.5vw;
  p{
    font-size:30px;
    margin: 20px 0 0 0;
    +p{
    }
  }
  @media (max-width:${MediaQueries.max}px){
    p{font-size:30px;}
  }
  @media (max-width:${MediaQueries.midium}px){
    p{font-size:30px;}
  }
  @media (max-width:${MediaQueries.mini}px){  
    p{font-size:30px;}
  }
`
